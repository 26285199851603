class SwitchPdfViewer {
    private pdfEndPoint = window.location.origin + "/Client2/ViewAndPrintPdf/ViewSwitchRequestForm?switchId=";

    showPdfInNewTab(switchId: string, openPrint: boolean = true) {
        var pdfLink = this.pdfEndPoint + switchId;
        let pdfWin: Window = this.openInNewTab(pdfLink);
        if (openPrint) {
            pdfWin.print();
        }
    }

    private openInNewTab(url:string ): Window {
        var win: Window = window.open(url, '_blank');
        win.focus();
        return win;
    }
}